import styled from "@emotion/styled"
import { breakpoints, colors, dimensions } from "../styles"

export const MainContainer = styled.div`
  width: 100%;
  display: flex;
  font-family: Cormorant;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: white;
  max-width: ${dimensions.containerLogin};
  margin: auto;
  border-radius: 10px;
  box-shadow: rgba(167, 167, 167, 0.06) 0px 0px 15px 18px;
  @media (max-width: ${breakpoints.phablet}px) {
    padding: 40px 20px;
    flex-direction: column;
    max-width: 100%;
    padding-top: 30px;
    width: 100%;
    margin-top: 0px;
    &.focused {
      margin-top: -180px;
    }
  }
`

export const ConfirmationContainer = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  max-width: 600px;
  margin: auto;
  margin: 6vh 0;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 7px #00000008;
  @media (max-width: ${breakpoints.phablet}px) {
    margin: 0;
    flex-direction: column;
  }
`

export const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 6vh 0;
  @media (max-width: ${breakpoints.phablet}px) {
    margin: 0;
  }
`

export const WrapperPassword = styled.div`
  position: relative;
`

export const IconPassword = styled.button`
  position: absolute;
  margin: 0px;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  height: 100%;
  display: flex;
  align-items: center;
  right: 0;
  width: 35px;
`

export const TitleLoading = styled.span`
  font-size: 1rem;
  margin-left: 20px;
  font-family: Lato;
`

export const TitleConfirmation = styled.span`
  font-size: 1rem;
  margin-left: 30px;
  @media (max-width: ${breakpoints.phablet}px) {
    width: 100%;
    padding: 0;
    margin-left: 0px;
    margin-top: 25px;
  }
`

export const WrapperHeading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  padding: 0 15px;
  @media (max-width: ${breakpoints.phablet}px) {
    width: 100%;
    padding: 0;
  }
`

export const LoginWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  padding-right: 30px;
  padding-bottom: 25px;
  padding-top: 10px;
  @media (max-width: ${breakpoints.phablet}px) {
    width: 100%;
    padding: 0 20px;
  }
`

export const WrapperBtn = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  margin-top: 30px;
`

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`

export const LabelForm = styled.span`
  font-size: 1rem;
`


export const TitleForm = styled.span`
  font-size: 1.3rem;
  margin-top: 20px;
  @media (max-width: ${breakpoints.phablet}px) {
    
  }
`

export const ButtonLogin = styled.button`
  display: flex;
  font-family: Lato;
  cursor: pointer;
  border: 0;
  padding: 14px 18px;
  line-height: 1;
  background: ${colors.softBeige};
  text-align: center;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  color: ${colors.pinkDark};
  margin: 0;
  outline: none;
  font-size: 1rem;
  margin-left: 15px;
  transition-duration: 0.2s;
  &:hover{
    color: white;
    background: ${colors.redWaskoll};
  }
`

export const ButtonLoginAlt = styled.button`
  display: flex;
  cursor: pointer;
  font-family: Lato;
  border: 0;
  padding: 10px 20px;
  background: none;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  color: ${colors.darkWaskoll};
  margin: 0;
  outline: none;
  font-size: 1rem;
  transition-duration: 0.2s;
  &:hover{
    color: ${colors.redWaskoll};
  }
`

export const WrapperForgotPassword = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`

export const LabelForgotPassword = styled.button`
  margin-bottom: 8px;
  color: #383838;
  cursor: pointer;
`

export const Label = styled.p`
  font-size: 1.2rem;
  margin-bottom: 15px;
  color: #383838;
  margin-right: 20px;
`

export const Divider = styled.div`
  height: 2px;
  margin: 25px 0;
  width: 90%;
  background: #00000008;
  box-shadow: 0 0 9px 0px #0000000d;
`

export const FormInput = styled.input`
  display: flex;
  font-family: Lato;
  font-size: 1rem;
  width: ${props => props.isMobile && "100%"};
  color: #383838;
  outline: none;
  flex-direction: column;
  min-height: 45px;
  border-radius: 5px;
  padding: 0 20px;
  cursor: pointer;
  border: 1px solid rgb(56 56 56 / 0.08);
  background-color: #fff;
  box-shadow: 0 0 5px 0px #0000000d;
  background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
  &:focus {
    box-shadow: inset 0 0 2px rgba(67, 90, 111, 0.14), inset 0 0 0 1px #579ad9, 0 0 0 3px rgba(16, 112, 202, 0.14);
  };
  &.input-error {
    box-shadow: inset 0 0 2px rgba(67, 90, 111, 0.14), inset 0 0 0 1px #ec4c47a3, 0 0 0 3px #ec4c4714;
  }
`

export const AlertBlock = styled.div`
  margin: 0 20px;
`

export const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  margin-top: 30px;
  margin-right: 15px;
`