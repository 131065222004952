import React, { useEffect, useState } from "react"
import { Redirect } from '@reach/router'
import { isLoggedIn, SignIn, SignUp } from "../utils/auth"
import { navigate } from "gatsby"
import useAuth from "../hooks/useAuth"
import { AiOutlineCheck, AiOutlineAlert } from "react-icons/ai"
import { Alert, Spinner, TextInput } from "evergreen-ui"
import { colors } from "../components/styles"
import { FaUserLock, FaEye, FaEyeSlash } from "react-icons/fa"
import {
  MainContainer,
  MainWrapper,
  WrapperHeading,
  TitleLoading,
  TitleForm,
  InputWrapper,
  LoginWrapper,
  ButtonLogin,
  ButtonLoginAlt,
  WrapperBtn,
  IconPassword,
  WrapperPassword,
  ConfirmationContainer, TitleConfirmation, WrapperForgotPassword, LabelForgotPassword, Label,
  FormInput,
  LoadingContainer, AlertBlock
} from "../components/Login/StyledLoginPage"
import { sendForgotPasswordCode, sendNewPassword } from "../utils/userHelpers"

export default () => {
  const [user, setUser] = useState({email: "", password:""})
  const [isError, setError] = useState(false)
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isRegisterSuccess, setRegisterSuccess] = useState(false)
  const [isSuccessNewPassword, setSuccessNewPassword] = useState(false)
  const [isForgotError, setForgotError] = useState(false)
  const [isForgotPassword, setForgotPassword] = useState(false)
  const [isKeyboardView, setKeyboardView] = useState(false)
  const { setUserSession, currentUser } = useAuth()

  useEffect(() => {
    if (currentUser.isLoggedIn) {
      if (currentUser.role === "admin") navigate("/dashboard/")
      if (currentUser.role === "customer") navigate("/account/")
    }
  }, [currentUser])

  const handleChange = (e, key) => {
    setUser({
      ...user,
      [key]: e.target.value
    })

    if (isError) setError(false)
  }

  const handlerRegister = (e) => {
    e.preventDefault()
    if (user.password === "") setError({
      password: "Vous devez saisir un mot de passe"
    })
    if (user.email === "") setError({
      email: "Vous devez saisir une adresse email"
    })
    SignUp(user)
      .then(() => {
        console.info("SUCCESS REGISTER")
        setRegisterSuccess(user.email)
      })
      .catch(err => {
        if (err.code === "UsernameExistsException") {
          setError({
            email: "Il existe déjà un compte pour cet email."
          })
        }
        if (err.code === "InvalidParameterException") {
          if (err.message.includes("email")) {
            setError({
              email: "Cette adresse email est invalide"
            })
          }
          else setError({
            password: "Votre mot de passe doit avoir\n au moins 6 caractères."
          })

        }
      })
  }

  const handlerPassword = (e) => {
    e.preventDefault()
    setPasswordVisible(!passwordVisible)
  }

  const handlerLogin = (e) => {
    e.preventDefault()
    if (user.email !== "" && user.password !== "") {
      setIsLoading(true)
      SignIn(user)
        .then(data => {
          setUserSession(data.username)
        })
        .catch(err => {
          console.error("ERROR LOGIN", err)
          if (err.code === "NotAuthorizedException") setError({password: "Le mot de passe est incorrect"})
          else if (err.code === "UserNotFoundException") setError({email: "Cette adresse email n'existe pas"})
          setIsLoading(false)
        })
    }
  }

  const handleFocusInput = (e) => {
    e.preventDefault()
    setKeyboardView(!isKeyboardView)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handlerLogin(event)
    }
  }

  const handlerForgotPassword = (e) => {
    e.preventDefault()
    setIsLoading(true)
    if (user.email === ""){
      setIsLoading(false)
      setError({email: "Vous devez renseigner une adresse email à récupérer"})
    }
    else {
      sendForgotPasswordCode(user.email)
        .then(() => {
          setIsLoading(false)
          setForgotPassword(true)
        })
        .catch(err => {
          console.error("ERROR FORGOT", err)
          setIsLoading(false)
          if (err.code === "UserNotFoundException") setError({email: "Cette adresse email n'existe pas"})
          if (err.code === "LimitExceededException") {
            setForgotError(true)
            setTimeout(() => {
              setForgotError(false)
            }, 10000)
          }
        })
    }
  }

  const handlerNewPassword = (e) => {
    e.preventDefault()
    setIsLoading(true)
    if (user.newPassword === ""){
      setError({newPassword: "Vous devez renseigner un nouveau mot de passe"})
      setIsLoading(false)
    }
    else {
      sendNewPassword(user.email, user.code, user.newPassword)
        .then(res => {
          setForgotPassword(false)
          setSuccessNewPassword(true)
          setTimeout(() => {
            setSuccessNewPassword(false)
          }, 3000)
          setIsLoading(false)
        })
        .catch(err => {
          if (err.code === "CodeMismatchException") setError({code: "La code de vérification est invalide."})
          if (err.code === "InvalidParameterException") setError({
            newPassword: "Votre mot de passe doit avoir au moins 6 caractères."
          })
          setIsLoading(false)
        })
    }
  }

  if (isRegisterSuccess) return (
    <MainWrapper>
      <ConfirmationContainer>
        <AiOutlineCheck size={48} color={"#1dd1a1"}/>
        <TitleConfirmation>Inscription validée ! Vous devez confirmer votre compte en cliquant sur le lien qui
          vous a été envoyé à l'adresse email <span
            style={{ textDecoration: "underline", fontWeight: "bold" }}>{isRegisterSuccess}</span></TitleConfirmation>
      </ConfirmationContainer>
    </MainWrapper>
  )

  if (isSuccessNewPassword) return (
    <MainWrapper>
      <ConfirmationContainer>
        <AiOutlineCheck size={48} color={"#1dd1a1"}/>
        <TitleConfirmation>Votre mot de passe à bien été modifié, vous allez être redirigé afin de vous connecter.</TitleConfirmation>
      </ConfirmationContainer>
    </MainWrapper>
  )

  if (isForgotError) return (
    <MainWrapper>
      <ConfirmationContainer>
        <AiOutlineAlert size={38} color={"#ec1818"}/>
        <TitleConfirmation>Vous avez changé votre mot de passe plusieurs fois en peu de temps.<br/><br/> Pour des raisons de sécurité, veuillez réessayer plus tard ou contactez nous.</TitleConfirmation>
      </ConfirmationContainer>
    </MainWrapper>

  )

  return !currentUser.isLoggedIn ? (
    <MainWrapper>
      <MainContainer className={isKeyboardView && "focused"}>
        <WrapperHeading>
          <FaUserLock size={50} color={colors.darkGrey}/>
          {isForgotPassword ? (
            <TitleForm>Nouveau mot de passe</TitleForm>
          ) : (
            <TitleForm>Accéder à votre <b style={{fontSize: "1.4rem"}}>espace client</b></TitleForm>
          )}
          <AlertBlock>
            {isForgotPassword && (
              <Alert
                intent="success"
                title="Un code a été envoyé à l'adresse email saisie"
                marginTop={25}
              />
            )}

            {isError.code || isError.newPassword && (
              <Alert
                intent="danger"
                title={isError.code || isError.newPassword}
                marginTop={25}
              />
            )}
            {isError && (
              <Alert
                intent="danger"
                title={isError.email || isError.password}
                marginTop={25}
              />
            )}
          </AlertBlock>

        </WrapperHeading>
        {/*<Divider/>*/}
        {!isForgotPassword && (
          <LoginWrapper>
            <InputWrapper>
              <Label>Adresse email</Label>
              <FormInput
                className={!!isError.email && "input-error"}
                value={user.email}
                type={"email"}
                onKeyDown={handleKeyDown}
                onChange={(e) => handleChange(e, "email")}
              />
            </InputWrapper>
            <InputWrapper style={{marginTop: 10}}>
              <WrapperForgotPassword >
                <Label>Mot de passe</Label>
                <LabelForgotPassword tabIndex="-1" onClick={handlerForgotPassword}>Mot de passe oublié ?</LabelForgotPassword>
              </WrapperForgotPassword>
              <WrapperPassword>
                <IconPassword tabIndex="-1" onClick={handlerPassword}>
                  {!passwordVisible ? <FaEye color={"#444444"} size={15}/> : <FaEyeSlash color={"#444444"} size={15}/> }
                </IconPassword>
                <InputWrapper tabIndex="-1">
                  <FormInput
                    className={!!isError.password && "input-error"}
                    value={user.password}
                    type={passwordVisible ? "text": "password"}
                    onKeyDown={handleKeyDown}
                    onChange={(e) => handleChange(e, "password")}
                    onFocus={handleFocusInput}
                  />
                </InputWrapper>
              </WrapperPassword>
            </InputWrapper>
            {isLoading ? (
              <LoadingContainer>
                <Spinner size={38} color={"black"}/>
                <TitleLoading>Connexion en cours...</TitleLoading>
              </LoadingContainer>
            ) : (
              <WrapperBtn>
                <ButtonLoginAlt onClick={handlerRegister}>
                  <span>S'inscrire</span>
                </ButtonLoginAlt>
                <ButtonLogin type={"submit"} onClick={handlerLogin}>
                  <span>Se connecter</span>
                </ButtonLogin>
              </WrapperBtn>
            )}

          </LoginWrapper>
        )}
        {isForgotPassword && (
          <LoginWrapper>
            <InputWrapper>
              <Label>Code de vérification</Label>
              <FormInput
                marginTop={10}
                height={42}
                isInvalid={!!isError.code}
                name="code"
                onChange={handleChange}
              />
            </InputWrapper>
            <InputWrapper>
              <WrapperForgotPassword tabIndex="-1">
                <Label>Nouveau mot de passe</Label>
              </WrapperForgotPassword>
              <WrapperPassword >
                <IconPassword onClick={handlerPassword}>
                  {!passwordVisible ? <FaEye color={"#444444"} size={15}/> : <FaEyeSlash color={"#444444"} size={15}/> }
                </IconPassword>
                <InputWrapper>
                  <FormInput
                    height={42}
                    isInvalid={!!isError.newPassword}
                    name="newPassword"
                    type={passwordVisible ? "text": "password"}
                    onChange={handleChange}
                  />
                </InputWrapper>
              </WrapperPassword>
            </InputWrapper>
            <WrapperBtn>
              <ButtonLogin onClick={handlerNewPassword}>
                <span>Valider le nouveau mot de passe</span>
              </ButtonLogin>
            </WrapperBtn>
          </LoginWrapper>
        )}
      </MainContainer>
    </MainWrapper>
  ) : (
    <Redirect
      noThrow
      from="/account/"
      to="/"
    />
  )

}
